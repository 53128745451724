/**
* @Description 龙芯应用公社
* @author Loongson.Quzhenhong
* @date 21-7-13
*/
<template>
  <div class="cate">
    <div class="cate_nav">
      <div class="item" v-for="(item, index) in navList" :class="[currentIndex === index ? 'bgw' :'']"
           @click="navItem(index, item.id)">{{item.name}}
      </div>
    </div>
    <div class="option">
      <div class="op_tol">
        <div class="op_item" @click="smallNavClick(index, item.value)"
             :class="[currentSmallIndex === index ? 'act' :'']" v-for="(item, index) in smallNav">{{item.name}}
        </div>
      </div>
    </div>
    <el-row :gutter="20" class="cate_content" v-if="dataList.length">
      <el-col :xs="10" :sm="10" :md="10" :lg="8" :xl="8" v-for="(item, index) in dataList" :key="item.id">
        <Item :time="item.createTime" :name="item.name" :currentPage="currentPage" :catePageIndex="currentIndex" :cateId="parseInt(cateId)"
              :orderType="orderType" :size="item.appSize" :star="item.score" :softId="item.id" :type="type" :iconUrl="item.logoUrl"/>
      </el-col>
    </el-row>
    <!--<div class="cate_content" v-if="dataList.length">
      <Item v-for="(item, index) in dataList" :key="item.id" :time="item.updateTime" :name="item.name"
            :size="item.appSize" :star="item.score" :softId="item.id" :type="type" :iconUrl="item.logoUrl"/>

    </div>-->
    <div class="none" v-else>
      {{alterTitle}}
    </div>
    <div class="page_">
      <el-pagination
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="total">
      </el-pagination>
    </div>


  </div>
</template>

<script>
  import Item from '@/components/item/Item.vue'
  import {getCateSoft, getNavList} from '@/utils/network'

  export default {
    name: 'CateSoft',
    data() {
      return {
        currentIndex: this.$store.state.isLastPage?parseInt(this.$store.state.catePageIndex):0,
        orderType: this.$store.state.isLastPage?(this.$store.state.orderType):'defalut',
        dataList: [],
        cateId: null,
        navList: [],
        //分页
        currentPage: this.$store.state.isLastPage?parseInt(this.$store.state.lastPage):1,
        pageSize: 12,
        total: null,
        currentSmallIndex: this.$store.state.isLastPage?this.$store.state.orderType!='defalut'?this.$store.state.orderType==='createtime'?1:2:0:0,
        alterTitle: '加载中...',
        smallNav: [
          {name: '更新时间', value: 'defalut'},
          {name: '下载量', value: 'createtime'},
          {name: '评分', value: 'appscore'}]
      }
    },
    computed: {
      type() {
        return this.navList[this.currentIndex] && this.navList[this.currentIndex].name
      },
      getCateId() {
        return this.$store.state.cateId
      }
    },
    async created() {
      if(this.$store.state.isLastPage){
        var cate = parseInt(this.$store.state.catePageIndex)
        const {data: res} = await getNavList({})
        if (res.code !== 200) return this.$message.error('请求失败')
        this.navList = res.data
        this.cateId = (this.navList)[cate].id
      }else if(this.getCateId){
        this.cateId = this.getCateId
      }else{
        const {data: res} = await getNavList({})
        if (res.code !== 200) return this.$message.error('请求失败')
        this.navList = res.data
        this.cateId = (this.navList)[0].id
      }
      if (this.getCateId) {
        await this.getData()
        await this.getNavList()
        this.updateCurrentIndex()
      }else{
        await this.getNavList()
        await this.getData()
      }
      this.$store.dispatch('isLastPage', {
        isLastPage: false
      })
      this.$store.dispatch('orderType', {
        orderType: 'defalut'
      })
    },
    methods: {
      //分页切换
      currentChange(index) {
        this.currentPage = index
        this.getData()
        window.scrollTo(0, 0);

      },
      smallNavClick(index, orderType) {
        this.currentSmallIndex = index
        this.orderType = orderType
        this.currentPage = 1
        this.getData()
      },
      updateCurrentIndex() {
        this.currentIndex = this.navList.findIndex(item => item.id === this.cateId)
      },
      navItem(index, id) {
        this.currentSmallIndex=0
        this.orderType='defalut'
        this.currentPage = 1;
        this.currentIndex = index
        this.cateId = id
        this.getData()
      },
      async getNavList() {
        const {data: res} = await getNavList({})
        if (res.code !== 200) return  //this.$message.error('请求失败')
        this.navList = res.data
      },
      async getData() {
        this.alterTitle = '加载中...'
        const {data: res} = await getCateSoft({
          id: this.cateId,
          page: this.currentPage,
          per_page: this.pageSize,
          orderType: this.orderType,
          sourceType:'web'
        })
        if (res.code !== 200) return this.$message.error('请求失败')
        this.dataList = res.data.vodata
        this.total = res.data.total
        // console.log(this.dataList)
        this.alterTitle = '暂无数据'
        // this.$message.success('获取成功')
        //console.log(res)
      }
    },
    components: {
      Item
    }
  }
</script>

<style lang="less" scoped>
  .cate {
    width: 80%;
    min-height: 670px;
    margin: 20px auto;
    background-color: #fff;

    .option {
      display: flex;
      justify-content: flex-end;

      .op_tol {
        display: flex;
        width: 180px;
        margin-bottom: 12px;
        justify-content: space-around;
        font-size: 14px;
        color: rgba(0, 0, 0, .6);

        .act {
          color: red;
        }
      }
    }

    .cate_nav {
      width: 100%;
      height: 50px;
      line-height: 50px;
      justify-content: space-around;
      display: flex;
      background-color: #ddd;
      margin-bottom: 16px;

      .bgw {
        background-color: #fff;
        font-weight: 700;
      }

      .item {
        font-size: 18px;
        text-align: center;
        width: 153px;
        cursor: pointer;
      }
    }

    .cate_content {
      padding: 0 10px;
      min-height: 460px;
      //display: flex;
      //justify-content: space-around;
      flex-wrap: wrap;
      //margin-bottom: 20px;
    }

    .none {
      font-size: 20px;
      min-height: 460px;
      font-weight: 600;
      margin-left: 60px;
      padding-bottom: 30px;
    }

  }

</style>
